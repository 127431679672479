export enum SolvingResult {
  CORRECT = 'correct',
  WRONG = 'wrong',
  NOT_KNOW = 'not-know',
}

export enum SolvingStatus {
  NOT_SOLVED = 'not-solved',
  NOW_SOLVING = 'now-solving',
  SOLVED = 'solved',
  NOT_UNDERSTAND = 'not-understand',
  WAIT = 'wait',
  TAKING_EXPLANATION = 'taking-explanation',
}

export enum StudyStep {
  FIRST_SOLVING = 'first-solving',
  SECOND_SOLVING = 'second-solving',
  TAKING_LECTURE = 'taking-lecture',
  REVIEW_NOTE = 'review-note',
  FIRST_REVIEW = 'first-review',
  SECOND_REVIEW = 'second-review',
  COMPLETED = 'completed',
  DESCRIPTION = 'description',
}

export enum STUDY_STATUS_KOR {
  FIRST_SOLVING = '1차 학습',
  SECOND_SOLVING = '2차 학습',
  TAKING_LECTURE = '해설 보기',
  REVIEW_NOTE = '오답 노트',
  FIRST_REVIEW = '복습1',
  SECOND_REVIEW = '복습2',
  DESCRIPTION = '설명하기',
  COMPLETED = '완료',
}

export enum AssignmentStudyPurpose {
  MAIN_STUDY = 'main-study',
  FIRST_REVIEW = 'first-review',
  SECOND_REVIEW = 'second-review',
  DESCRIPTION = 'description',
}

export enum ProblemStatusType {
  NORMAL = 'NORMAL',
  DESCRIPTION = 'DESCRIPTION',
}

export enum StudyItemType {
  PROBLEM = 'problem',
  CONCEPT = 'concept',
  READING_MATERIALS = 'reading-materials',
  NO_CONTENT = 'no-content',
  NO_LECTURE_PROBLEM = 'no-lecture-problem',
}

export enum AnswerType {
  CHOICE = 'choice',
  MULTIPLE_CHOICE = 'multiple-choice',
  SUBJECTIVE = 'subjective',
  SUBJECTIVES_IN_ORDER = 'subjectives-in-order',
  SUBJECTIVES_IN_NO_ORDER = 'subjectives-in-no-order',
  DESCRIPTIVE = 'descriptive',
  NOTE = 'note',
}

export enum CanvasPencilWritingMethod {
  PREMIUM = 'premium',
  STANDARD = 'standard',
  EXTENDED = 'extended',
}

export enum CanvasBrushColorValue {
  BLACK = 'black',
  RED = 'red',
  BLUE = 'blue',
  GREEN = 'green',
}

export enum CanvasBrushColorCode {
  BLACK = '#192029',
  RED = '#F64857',
  BLUE = '#226AFA',
  GREEN = '#00B268',
}

export enum CanvasBrushWidth {
  SMALL = 1,
  MEDIUM = 2,
  LARGE = 4,
}

export enum CanvasControllerBrushMode {
  BRUSH = 'brush',
  ERASER = 'eraser',
}
export enum CanvasControllerEraserMode {
  PIXEL_ERASER = 'pixelEraser',
  OBJECT_ERASER = 'objectEraser',
}
