export enum StudyContentProgressStatus {
  TOTAL_COUNT = 'totalProblemCount',
  GRADED_COUNT = 'gradedProblemCount',
  COMPLETE_COUNT = 'completedProblemCount',
  CORRECT_COUNT = 'correctProblemCount',
  WAIT_COUNT = 'waitProblemCount',
  WRONG_COUNT = 'wrongProblemCount',
  CORRECT_RATE = 'correctRate',
  PROGRESS_RATE = 'progressRate',
}

export enum MyContentType {
  NORMAL_CONTENT = 'NORMAL_CONTENT',
  WRONG_CONTENT = 'WRONG_CONTENT',
}

export enum AssignmentType {
  NORMAL = 'NORMAL',
  NORMAL_TEST = 'NORMAL_TEST',
  WRONG_TEST = 'WRONG_TEST',
  WEEKLY_WRONG_TEST = 'WEEKLY_WRONG_TEST',
  LEVEL_TEST = 'LEVEL_TEST',
  RETEST = 'RETEST',
}

export const TEST_ASSIGNMENT_TYPES = new Set([
  AssignmentType.NORMAL_TEST,
  AssignmentType.WRONG_TEST,
  AssignmentType.WEEKLY_WRONG_TEST,
  AssignmentType.LEVEL_TEST,
  AssignmentType.RETEST,
]);

export enum TestFilteringLabel {
  TOTAL = '전체',
  SCHOOL_TEST = '학교기출문제',
  MOCK_TEST = '모의고사',
  LEVEL_TEST = '진단 테스트',
  CHAPTER_TEST = '단원 테스트',
  TOTAL_TEST = '총괄 테스트',
  DAILY_TEST = '데일리 테스트',
  REVIEW_TEST = '복습 테스트',
  WRONG_TEST = '오답 테스트',
  WEEKLY_WRONG_TEST = '주간 오답 테스트',
}

export enum ReviewPageExtractionType {
  FIRST_SOLVING_WRONG = 'FIRST_SOLVING_WRONG',
  BOOKMARKED_BY_TEACHER = 'BOOKMARKED_BY_TEACHER',
  BY_MASTERY_LEVEL = 'BY_MASTERY_LEVEL',
}
