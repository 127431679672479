import Head from 'next/head';
import '../styles/reset.scss';
import '../styles/globals.scss';
import '../styles/katex.css';
import '../styles/_variables.scss';
import 'public/static/fonts/fonts.css';
import 'react-photo-view/dist/react-photo-view.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-phone-input-2/lib/style.css';
import React, { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useRouter } from 'next/router';
import { useStudentInfoObjectState, useStudentListState } from '@atoms/states';
import { useEventPropertiesStore } from '@store/common';
import { Slide } from 'react-toastify';
import { configureScope, Scope } from '@sentry/nextjs';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { queryClientConfig } from 'queries/core/config';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { appWithTranslation } from 'next-i18next';
import nextI18nConfig from '../next-i18next.config';
import { CustomToastContainer } from '@styles/common/toastStyle';
import CheckFillIcon from '@asset/svg/CheckFillIcon';
import CancelFillIcon from '@asset/svg/CancelFillIcon';
import InfoFillIcon from '@asset/svg/InfoFillIcon';
import { useUserInfoQuery } from '@apis/query/user';
import 'swiper/css';
import * as amplitude from '@amplitude/analytics-browser';
import { UserType } from '@asset/enum/UserEnum';
import { useCheckIsIncorrectBuildIdQuery } from '@apis/query/core';
import NotifyUpdateToast from '@components/common/toast/NotifyUpdateToast';
import { GlobalStyle } from '@styles/globalsStyles';
import { Settings } from 'luxon';
import Gnb from '@components/common/nav/Gnb';
import { useWindowSize } from '@public/hooks/useWindowSize';
import '@sendbird/uikit-react/dist/index.css';
import SendbirdWrapper from '@components/chat/ChatRoom';
import { useChatStore } from '@store/chat';
import { getSendbirdUserInfo } from '@apis/api/chat';
import _ from 'lodash';
import QuestionChatDetailModal from '@components/chat/QuestionChatDetailModal';
import {
  getIsVisibleChatButton,
  registerFCMToSendbird,
  withSendbird,
} from '@asset/function/chat';

dayjs.extend(utc);
dayjs.extend(timezone);

function CheckLoginState({ children }) {
  const { data: user } = useUserInfoQuery();
  const { data: isIncorrectBuildId } = useCheckIsIncorrectBuildIdQuery({
    refetchInterval: 60000,
  });

  const router = useRouter();
  const [studentList] = useStudentListState();
  const [, setStudentInfoObject] = useStudentInfoObjectState();
  const { isMobileView } = useWindowSize();
  const isErrorPage =
    router.pathname === '/_error' ||
    router.pathname === '/404' ||
    router.pathname === '/500';
  const isAccountPage = router.pathname.includes('account');

  useEffect(() => {
    amplitude.init(
      process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
      user ? String(user.userId) : null,
      {
        minIdLength: 1,
        defaultTracking: { pageViews: true },
      },
    );
    const identifyEvent = new amplitude.Identify();
    if (user) {
      identifyEvent.set('userId', user.userId);
      identifyEvent.set('timezone', user?.timezone);
      identifyEvent.set('language', user?.language);
      identifyEvent.set(
        user.type === UserType.TEACHER ? 'teacherId' : 'studentId',
        user.id,
      );

      configureScope((scope: Scope) => {
        scope.setUser({
          type: user.type,
          id: user.id.toString(),
          name: user.name,
          timezone: user.timezone,
          language: user.language,
        });
      });
      if (user.type === UserType.STUDENT) Settings.defaultZone = user.timezone;
    }
    amplitude.identify(identifyEvent);
    return () => {
      configureScope((scope: Scope) => scope.clear());
    };
  }, [user]);

  useEffect(() => {
    const _studentInfoObject = {};
    if (studentList) {
      if (studentList.myStudentList || studentList.otherStudentList) {
        studentList.myStudentList?.map(
          (studentInfo) =>
            (_studentInfoObject[`${studentInfo.id}`] = studentInfo),
        );
        studentList.otherStudentList?.map(
          (studentInfo) =>
            (_studentInfoObject[`${studentInfo.id}`] = studentInfo),
        );
        setStudentInfoObject(_studentInfoObject);
      }
    }
  }, [studentList]);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    let closeKakaoInAppBrowser = null;
    if (/kakaotalk/i.test(userAgent)) {
      window.location.href = `kakaotalk://web/openExternal?url=${encodeURIComponent(
        window.location.href,
      )}`;
      closeKakaoInAppBrowser = setTimeout(() => {
        if (/ipad|iphone|ipod/.test(userAgent))
          window.location.href = 'kakaoweb://closeBrowser';
        else if (/android/.test(userAgent))
          window.location.href = 'kakaotalk://inappbrowser/close';
        else self.close();
      }, 10);
    }
    return () =>
      closeKakaoInAppBrowser ? clearTimeout(closeKakaoInAppBrowser) : null;
  }, []);

  const { setUnreadCount } = useChatStore();
  const { data: sendbirdUserInfo } = useQuery(
    ['GET_SENDBIRD_USER_INFO', user?.userId],
    () => getSendbirdUserInfo(user?.userId),
    {
      enabled: !_.isNil(user?.userId),
    },
  );
  const sendbirdUserId = sendbirdUserInfo?.userId;
  const sendbirdAccessToken = sendbirdUserInfo?.accessToken;

  const setUpSendbird = async (
    sendbirdUserId: string,
    sendbirdAccessToken: string,
  ) => {
    await withSendbird(sendbirdUserId, sendbirdAccessToken, async (sb) => {
      if (!_.isNil(sb.groupChannel)) {
        const unread = await sb.groupChannel.getTotalUnreadMessageCount();
        setUnreadCount(unread);
      }
      await registerFCMToSendbird(sb);
    });
  };

  useEffect(() => {
    if (sendbirdUserId && sendbirdAccessToken) {
      setUpSendbird(sendbirdUserId, sendbirdAccessToken);
    }
  }, [sendbirdUserId, sendbirdAccessToken]);

  const isVisibleChatButton = getIsVisibleChatButton(
    sendbirdUserInfo,
    user?.type,
    user?.language,
  );

  return (
    <>
      {isIncorrectBuildId && <NotifyUpdateToast />}
      <section
        className="flex flex-col"
        style={{
          height: '100dvh',
        }}
      >
        {isAccountPage ||
        isErrorPage ||
        router.pathname.includes('study-report') ||
        router.pathname.includes('problem-solving') ||
        router.pathname.includes('/feedback/detail') ||
        isMobileView ? (
          <></>
        ) : (
          <Gnb isVisibleChatButton={isVisibleChatButton} />
        )}
        {children}
      </section>
    </>
  );
}

function MyApp({ Component, pageProps }) {
  const [queryClient] = useState(() => {
    return new QueryClient(queryClientConfig);
  });
  const router = useRouter();
  const { setProperties } = useEventPropertiesStore();
  const {
    selectedChannelUrl,
    setIsOpen,
    setUnreadCount,
    setClickedChatNotification,
  } = useChatStore();

  useEffect(() => {
    const onMessage = (event) => {
      if (event.data.action === 'push') {
        const pushChannelUrl = event.data.data.sendbird?.channel?.channel_url;
        if (selectedChannelUrl !== pushChannelUrl) {
          setUnreadCount(
            event.data.data.sendbird?.channel?.channel_unread_message_count,
          );
        }
      }
      if (event.data.action === 'notificationClick') {
        const clickedChannelUrl =
          event.data.data.sendbird?.channel?.channel_url;
        if (!_.isNil(clickedChannelUrl)) {
          setClickedChatNotification({ channelUrl: clickedChannelUrl });
          setIsOpen(true);
        }
      }
    };

    navigator?.serviceWorker?.addEventListener('message', onMessage);
    return () => {
      navigator?.serviceWorker?.removeEventListener('message', onMessage);
    };
  }, [selectedChannelUrl]);

  useEffect(() => {
    const chartTooltip = document.querySelector('div#chartjs-tooltip');
    if (chartTooltip) {
      chartTooltip.remove();
    }

    setProperties({
      url: typeof window !== 'undefined' ? window.location.href : '',
      path: router.asPath,
    });
  }, [router.asPath]);

  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_CLIENT_ID}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1"
              />
              <link rel="manifest" href="/manifest.json" />
              <link
                rel="shortcut icon"
                href="/common/logo/favicon.svg"
                type="image/x-icon"
              />
            </Head>
            <main>
              <CheckLoginState>
                <SendbirdWrapper />
                <QuestionChatDetailModal />
                <Component {...pageProps} />
              </CheckLoginState>
              <CustomToastContainer
                position="top-center"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
                icon={({ type }) => {
                  if (type === 'success') {
                    return <CheckFillIcon />;
                  } else if (type === 'info') {
                    return <CancelFillIcon />;
                  } else if (type === 'error') {
                    return <InfoFillIcon />;
                  }
                }}
              />
            </main>
            <style jsx>{`
              body {
                > div {
                  display: flex;
                  flex-direction: column;
                }
              }
            `}</style>
          </GoogleOAuthProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </>
  );
}

export default appWithTranslation(MyApp, nextI18nConfig);
