import {
  encodeQueryValue,
  getUrlByQueryParameterObj,
} from '@asset/function/queryStringFunctions';
import { authAPI } from '../core/instance';
import { toast } from 'react-toastify';
import {
  MaterialType,
  StudyContentPageType,
  StudyContentType,
  TotalCourse,
} from '@asset/enum/materialEnum';
import { LayoutType, ProblemStatusDetailType } from '@asset/type/common/type';
import {
  ProblemStatusType,
  SolvingResult,
  StudyItemType,
  StudyStep,
} from '@asset/enum/problemSolvingEnum';
import { AssignmentType, MyContentType } from '@asset/enum/studyContentEnum';
import { StudyItem } from '@asset/type/common/type';
import { CommonResponse, MediaContentMediaType } from '@apis/common/response';
import { WrongPageExtractionType } from '@components/common/modal/wrong-problem-material/WrongPageExtractionTypeForm';
import { Mastery, MasteryCountInfo } from '@asset/type/mastery';
import { ReportType } from '@asset/type/problemResultDetailViewType';
import { postMyStudyContent } from './dashboard';
import { StudyPurpose } from 'constants/enum';
import { MissionCustomOption } from '@components/common/form/StudyMaterialOptionForm';
import { DateTime } from 'luxon';
import qs from 'qs';
import _ from 'lodash';

export interface ContentValue {
  problemStatusId: number;
  pageId: number;
  problemIndex: number;
  index: string;
  subIndex: string;
  // TODO:  type 이름 변경
  studyItemType: StudyItemType;
  problemLectureMediaTypes: MediaContentMediaType[];
  problemStatusDetails: ProblemStatusDetailType[];
  studyHistory?: StudyHistory;
  problemStatusType: ProblemStatusType;
}
export interface ProblemListResponse {
  success: boolean;
  pagination: {
    hasMore: boolean;
    nextOffset: number | null;
    previousOffset: number | null;
  };
  progressInfo: {
    totalProblemStatusCount: number;
    totalCompletedCount: number;
    progressRate: number;
    correctRate: number;
  };
  contentValues: ContentValue[];
  // TODO: contentType, studyType 구분 해서 타입 지정하기
  contentType: string;
  studyType: string;
  timeLimit: number;
}

export const getProblemList = async (
  pageType,
  studyContentId,
  pickedClassificationId,
  pickedClassificationType,
  problemFiltering,
  limit = 100,
  offset = 0,
): Promise<ProblemListResponse> => {
  let apiUrl = `/api/teachers/study-content-detail/${studyContentId}/?limit=${limit}&offset=${offset}`;

  if (problemFiltering) {
    problemFiltering
      .split(',')
      .map((filtering) => (apiUrl += `&${filtering}=${true}`));
  }
  if (pageType) apiUrl += `&pageType=${pageType}`;

  if (pickedClassificationId && pickedClassificationType) {
    apiUrl += `&classificationType=${pickedClassificationType}&classificationId=${pickedClassificationId}`;
  }

  const data = await authAPI
    .get<ProblemListResponse>(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getMasteryInformation = async (
  query:
    | {
        studyContentType: StudyContentPageType;
        contentId: number;
        classificationId?: number;
        classificationType?: string;
        completedOnly?: boolean;
      }
    | {
        studentId: number;
        assignmentDueDateTimeRangeStart: DateTime;
        assignmentDueDateTimeRangeEnd: DateTime;
        completedOnly?: boolean;
      },
): Promise<{
  success: true;
  statistics: {
    masteryInfo: {
      studyItemCounts: {
        masteryLevel: Mastery;
        count: number;
      }[];
    };
  };
  masteryCountInfo: MasteryCountInfo;
}> => {
  let apiUrl = `/api/study-data/study-content-statistics/`;
  const _query = { ...query };
  if ('studyContentType' in query) {
    _query[
      query.studyContentType === StudyContentType.MY_ASSIGNMENT
        ? 'assignmentId'
        : query.studyContentType === StudyContentType.MY_STUDY_MATERIAL ||
          query.studyContentType === StudyContentType.MY_TEST
        ? 'myContentId'
        : 'myStudyResourceId'
    ] = query.contentId;
    if ('studyContentType' in _query) {
      delete _query.studyContentType;
    }
    if ('contentId' in _query) {
      delete _query.contentId;
    }
    if ('classificationId' in _query && _.isNil(_query.classificationId)) {
      delete _query.classificationId;
    }
    if ('classificationType' in _query && _.isNil(_query.classificationType)) {
      delete _query.classificationType;
    }
  }
  if ('assignmentDueDateTimeRangeStart' in query) {
    _query['assignmentDueDateTimeRangeStart'] =
      query.assignmentDueDateTimeRangeStart.toISO();
    _query['assignmentDueDateTimeRangeEnd'] =
      query.assignmentDueDateTimeRangeEnd.toISO();
  }
  apiUrl += '?' + qs.stringify(_query);

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      const masteryCountInfo = {};
      _.map(
        response.data.statistics.masteryInfo.studyItemCounts,
        (masteryInfo) => {
          masteryCountInfo[masteryInfo.masteryLevel] = masteryInfo.count;
        },
      );
      return { ...response.data, masteryCountInfo };
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export interface StudyHistory {
  pageId: number;
  problemStatusDetails: {
    id: number;
    problemStatusId: number;
    pageId: number;
    studyStep: StudyStep;
    solvingResult: SolvingResult;
    studentCompletedAt: string;
    myContent: {
      id: number;
      createdAt: string;
      subTitle: string;
    };
    assignments: [
      {
        id: number;
        createdAt: string;
        subTitle: string;
      },
    ];
  }[];
}

export const getStudyHistories = async (
  pageIds: number[],
  studentId: number,
): Promise<{ success: boolean; histories: StudyHistory[] }> => {
  const pageIdsQuery = encodeQueryValue(pageIds);
  const apiUrl = `/api/study-data/study-histories/?pageIds=${pageIdsQuery}&studentId=${studentId}`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getClassificationAndLastProblem = async (
  pageType,
  studyContentId,
  studentId,
  pageId,
) => {
  let apiUrl = `/api/study-data/tables/?pageType=${pageType}`;
  if (studyContentId) apiUrl += `&studyContentId=${studyContentId}`;
  if (studentId) apiUrl += `&studentId=${studentId}`;
  if (pageId) apiUrl += `&pageId=${pageId}`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getMaterialClassification = async (studyMaterialId) => {
  const apiUrl = `/api/materials/${studyMaterialId}/classification/`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getAssignmentPreview = async (studentId, requestData) => {
  const apiUrl = `/api/students/${studentId}/my-assignments/`;

  const data = await authAPI
    .post(apiUrl, requestData)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

interface CreateCustomMissionResponseData {
  success: boolean;
  studyContentId: number;
  overlappedProblemCount: number;
}
export const createCustomMission = async (requestData: {
  studyContentType: StudyContentType;
  studyContentId: number;
  checkedPageIdList?: number[] | null;
  subTitle: string;
  studyPurpose: StudyPurpose[];
  startDate: number;
  dueDate: number;
  timeLimit?: number;
  cutOffScore?: number;
  assignmentType: AssignmentType;
  options?: MissionCustomOption;
}): Promise<CreateCustomMissionResponseData> => {
  const apiUrl = `/api/teachers/study-content/customize/`;
  const data = await authAPI
    .patch(apiUrl, requestData)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((error) => {
      if (error.response) toast.error(error.response.data.message);
      return error.response.data;
    });
  return data;
};

export const createCustomStudyMaterialOrMyContent = async (requestData: {
  checkedPageIdList: number[] | null;
  studyContentType: 'my-study-content' | StudyContentType;
  studyContentId: number;
  materialName: string;
  materialType?: MaterialType;
  myContentType: MyContentType;
  studentId?: number;
}): Promise<CreateCustomMissionResponseData> => {
  const apiUrl = `/api/teachers/study-content/customize/`;
  const data = await authAPI
    .patch(apiUrl, requestData)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((error) => {
      if (error.response) toast.error(error.response.data.message);
      return error.response.data;
    });
  return data;
};

export const updateCustomMissionApi = async (
  requestData,
  editingAssignmentId,
) => {
  const apiUrl = `/api/study-data/assignments/edit/?assignmentId=${editingAssignmentId}`;

  const data = await authAPI
    .patch(apiUrl, requestData)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((error) => {
      if (error.response) toast.error(error.response.data.message);
      return error.response.data;
    });
  return data;
};

export const getAssignmentClassificationPreview = async (
  myStudyContentId,
  classificationId,
  classificationType,
) => {
  let apiUrl = `/api/students/my-assignments/preview/classification/?myStudyContentId=${myStudyContentId}`;
  if (classificationId) {
    apiUrl += `&classificationId=${classificationId}`;
  }
  if (classificationType) {
    apiUrl += `&classificationType=${classificationType}`;
  }

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const submitRestudyWrongProblemModal = async (requestData) => {
  const data = await authAPI
    .post(`/api/materials/restudy-wrong-problem/`, requestData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const submitProgressModal = async (
  pageType,
  studyContentId,
  pagedIds,
) => {
  const apiUrl = `/api/teachers/study-content-detail/check-progress/?studyContentId=${studyContentId}&pageType=${pageType}`;

  const data = await authAPI
    .patch(apiUrl, { pageIds: pagedIds })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const scoringApi = async (
  result,
  detailId,
  studyContentId,
  pageType,
) => {
  const apiUrl = `/api/teachers/problemstatusdetail/${detailId}/`;

  const data = await authAPI
    .patch(apiUrl, {
      solvingResult: result,
      studyContentId: studyContentId,
      studyContentType: pageType,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export interface ModalStudyItemDataResponse {
  success: boolean;
  surroundPage: {
    previousPageId: number;
    previousStudyItemType: StudyItemType;
    nextPageId: number;
    nextStudyItemType: StudyItemType;
  };
  layoutType: LayoutType;
  studyItem: StudyItem;
  studyItemDetail: {
    isBookmark: boolean;
    subIndexName: string;
    studyItemType: StudyItemType;
    course: TotalCourse;
    materialType: MaterialType;
    materialName: string;
    indexNameType: string;
    indexName: string;
    indexNumber: number;
    division: string;
    subIndexNumber: number;
  };
  pagination?: {
    currPage: number;
    totalPage: number;
    nextPage: number | null;
    previousPage: number | null;
  };
}

export const getModalStudyItemDataApi = async (
  studyContentId,
  pageType,
  pageId,
  problemStatusDetailId,
  reqPage,
  showAnswer,
  studyStatusType,
): Promise<ModalStudyItemDataResponse> => {
  let apiUrl = `/api/teachers/study-content-detail/${studyContentId}/detail/?pageType=${pageType}&pageId=${pageId}&showAnswer=${showAnswer}`;
  if (problemStatusDetailId)
    apiUrl += `&problemStatusDetailId=${problemStatusDetailId}`;
  if (reqPage) apiUrl += `&reqPage=${reqPage}`;
  if (studyStatusType) apiUrl += `&studyStatusType=${studyStatusType}`;

  const data = await authAPI
    .get<ModalStudyItemDataResponse>(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getProblemStatusDetailModalDatasApi = async (
  problemStatusDetailId: number,
): Promise<{
  success: boolean;
  errorReports: ReportType[];
  result: {
    isSolutionCheck: boolean;
    solvingResult: SolvingResult;
    studyStatusType: StudyStep;
    elapsedTime: number;
    chapter: string;
    section: string;
    indexNumber: number;
    indexName: string;
  };
}> => {
  const apiUrl = `/api/teachers/study-content-detail/modal/report/?problemStatusDetailId=${problemStatusDetailId}`;

  const data = await authAPI
    .get(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyContentReportData = async (
  analysisType,
  studyContentList,
  course,
  studentId,
) => {
  let url = `/api/teachers/study-content/report/?analysisType=${analysisType}`;
  if (studyContentList) url += `&studyContentList=${studyContentList}`;
  if (course) url += `&course=${course}`;
  if (studentId) url += `&studentId=${studentId}`;

  const data = await authAPI
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const reStudyApi = async (
  detailId,
  studyContentId,
  studyContentType,
) => {
  const apiUrl = `/api/teachers/problemstatusdetail/${detailId}/?studyContentId=${studyContentId}&studyContentType=${studyContentType}`;

  const data = await authAPI
    .delete(apiUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const submitFeedbackApi = async (studyStatusType, reqData) => {
  const data = await authAPI
    .patch(`/api/study-data/feedback/?type=${studyStatusType}`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const checkSolvingApi = async (problemStatusDetailId, reqData) => {
  const data = await authAPI
    .patch(
      `/api/teachers/problemstatusdetail/solution-check/${problemStatusDetailId}/`,
      reqData,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const patchHideContents = async (reqData) => {
  const data = await authAPI
    .patch(`/api/study-data/hide-contents/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getMaterialIdByCourseApi = async (course) => {
  const data = await authAPI
    .get(`/api/materials/material-level/?course=${course}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getPredictTimeAndProblemCountApi = async (reqData) => {
  const data = await authAPI
    .post('/api/study-data/supplement/preview/predict/', reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getStudyContentProgressInfoApi = async (
  pageType: StudyContentPageType,
  studyContentId: number,
  queryString: {
    classificationId: number;
    classificationType: string;
  } | null,
): Promise<StudyContentProgressInfoResponse> => {
  let api = `/api/study-data/${pageType}/${studyContentId}/progress/`;
  if (queryString) api += getUrlByQueryParameterObj('', queryString);
  const data = await authAPI
    .get<StudyContentProgressInfoResponse>(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export interface StudyContentProgressInfoResponse extends CommonResponse {
  waitProblemCount: number;
  correctProblemCount: number;
  gradedProblemCount: number;
  completedProblemCount: number;
  totalProblemCount: number;
  progressRate: number;
  correctRate: number;
}

export interface CreateWrongProblemMaterialRequestData {
  studyContentId: number;
  studyContentType: string;
  pageIds?: number[];
  subTitle: string;
  timeLimit: number | null;
  includeWrongProblem: boolean;
  similarProblemCount: number;
  wrongPageExtractionType: WrongPageExtractionType;
}

export interface CreateRestudyMaterialIncludeMultipleStudyContentRequestData {
  targets: (
    | {
        assignmentId: number;
      }
    | {
        myContentId: number;
        pageIds: number[];
      }
  )[];
  requestContentType:
    | StudyContentType.MY_TEST
    | StudyContentType.MY_STUDY_MATERIAL;
  subTitle: string;
  includeWrongProblem: boolean;
  similarProblemCount: number;
  wrongPageExtractionType: WrongPageExtractionType;
  timeLimit?: number;
  masteryLevels?: Mastery[];
}

export const createWrongProblemMaterialIncludeMultipleStudyContentAndMission =
  async (
    startDateTime: number,
    endDateTime: number,
    requestData: CreateRestudyMaterialIncludeMultipleStudyContentRequestData,
    cutOffScore: number,
  ) => {
    await authAPI
      .post(`/api/materials/restudies/`, requestData)
      .then(async (response) => {
        const wrongProblemMaterial = response.data;
        const missionResponse = await createCustomMission({
          studyContentType: StudyContentType.MY_ASSIGNMENT,
          studyContentId: wrongProblemMaterial.myContentId,
          checkedPageIdList: wrongProblemMaterial.pageIds,
          subTitle: requestData.subTitle,
          studyPurpose: [StudyPurpose.MAIN_STUDY],
          startDate: startDateTime,
          dueDate: endDateTime,
          timeLimit: requestData.timeLimit,
          cutOffScore: cutOffScore,
          assignmentType:
            requestData.requestContentType === StudyContentType.MY_TEST
              ? AssignmentType.NORMAL_TEST
              : AssignmentType.NORMAL,
        });
        if (missionResponse.success) {
          if (missionResponse.overlappedProblemCount) {
            toast.success(
              `중복된 ${missionResponse.overlappedProblemCount}문제를 자동으로 제외했어요.`,
            );
          }
          toast.success(
            `오답 ${
              requestData.requestContentType === StudyContentType.MY_TEST
                ? '테스트'
                : '복습자료'
            } 미션이 부여되었습니다.`,
          );
        }
      })
      .catch((error) => {
        if (error.response) toast.error(error.response.data?.message);
      });
  };

export const createLevelTestMaterialAndMission = async (
  studentId: number,
  levelTestReqBody: {
    pageType: StudyContentPageType;
    myContentType: MyContentType;
    subject: string;
    course: TotalCourse;
    materialType: MaterialType;
    materialName: string;
    timeLimit?: number;
  },
  missionReqBody: {
    subTitle: string;
    startDateTime: number;
    endDateTime: number;
  },
): Promise<CreateCustomMissionResponseData> => {
  return await postMyStudyContent(studentId, levelTestReqBody)
    .then(async (response) => {
      const newMaterial = response.newObject;
      const missionResponse = await createCustomMission({
        studyContentType: StudyContentType.MY_ASSIGNMENT,
        studyContentId: newMaterial.id,
        checkedPageIdList: null,
        subTitle: missionReqBody.subTitle,
        studyPurpose: [StudyPurpose.MAIN_STUDY],
        startDate: missionReqBody.startDateTime,
        dueDate: missionReqBody.endDateTime,
        timeLimit: null,
        cutOffScore: null,
        assignmentType: AssignmentType.NORMAL_TEST,
      });
      return missionResponse;
    })
    .catch((error) => {
      return error;
    });
};
